<template>
 <v-container>
    <v-row class="text-center">
      <v-col cols="12">
          <div class="resume">
            <v-col class="text-left" cols="12" sm="4">
            <div class="my-2">
              <v-btn @click="backPage" color="primary" rounded>Retour</v-btn>
            </div>
          </v-col>           
                  <h1>Récapitulatif de commande</h1>


                  <p> Vous avez sélectionné les licences suivantes : </p>
                  <ul>
                    <li v-for ="price in realyLicence" :key="price.name">{{price.quantity}} {{price.name}}</li>
                    
                  </ul>

                <p>Vous avez enregistré une licence pour les personnes reprises ci-dessous :</p>

                <table>
                  <thead>
                    <tr>
                      <td>Numéro de licence</td>
                      <td>Nom et prénom</td>
                      <td>Adresse</td>
                      <td>Téléphone</td>
                      <td>Email</td>
                      <td>Contact</td>
                      <td>Information Complémentaire</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for ="(licence,i) in licences" :key="i" >
                      
                      <td v-if="i >0">{{licence.licencie.num_licence}}</td>
                      <td v-if="i >0">{{licence.licencie.name}} {{licence.licencie.firstname}}</td>
                      <td v-if="i >0"> {{licence.licencie.address}}, {{licence.licencie.postal_code}}, {{licence.licencie.city}}</td>
                      <td  v-if="i>0">{{licence.licencie.phone}}</td>
                      <td  v-if="i>0">{{licence.licencie.email}}</td>
                      <td  v-if="i>0"><span v-for="(contact,i) in licence.licencie.child.contact" :key="i">
                        {{contact.name}} {{contact.firstname}} {{contact.parente}} {{contact.phone}}
                        </span>
                      </td>
                      <td  v-if="i>0">{{licence.licencie.child.info_complémentaires}}</td>
                      
                    </tr>
                  </tbody>
                </table>



                 
          </div>
       
      </v-col>

      
    </v-row>

     <v-row>
      <v-col cols="12"
        md="3"
        offset-md="3"
        class="text-center"
        >
              <div class="my-2">
      <v-btn :class="{ 'hidden': is_paye }" @click="paye('chèque')" large color="primary">Paiement par Chèque</v-btn>
              </div>
      </v-col>
      <v-col cols="12"
        md="3"
        class="text-center"
      >
          <div class="my-2">
      <v-btn :class="{ 'hidden': is_paye }" @click="paye('virement')" large color="primary">Paiement par Virement</v-btn>
      </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
          <p>Vous pouvez payer le montant de la commande par :</p>
          <p style="text-decoration:underline;font-style:italic;font-size:18px;margin-bottom:15px"> Virement  </p>
          <p>
          Paiement de l’intégralité du montant de la commande par virement (paiement en une fois uniquement) ; les coordonnées bancaires pour réaliser le virement vous seront transmises lors de la confirmation de votre commande.
          </p>
          <p>OU</p>
          <p style="text-decoration:underline;font-style:italic;font-size:18px;margin-bottom:15px">Chèque </p>
          <p>
          <ul>
            <li>Paiement de l’intégralité de la commande en une seule fois : le chèque sera débité au moment de l’inscription ;</li>
            <li>Paiement de la commande en 2 fois : 1 chèque représentant 50% du montant de la commande débité au moment de l’inscription, 1 second chèque représentant le solde restant débité en janvier. Les 2 chèques devront être remis au moment de l’inscription.</li>
          </ul>
          
          
          </p>
      </v-col>
    </v-row>
    
  </v-container>
 
</template>

<script>
// @ is an alias to /src

import {mapGetters, mapMutations} from  'vuex';
import api from '../../src/api/inscription.js';
export default {
  name: 'Resume',
  data : () => ({
      licences:{},
      prices : {},
      is_paye : false
     
  }),
  components: {
    
  },
  computed:{
    realyLicence : function(){
     
    
      return this.prices.filter(price => price.quantity >0 );
    }
  },
  methods : {
    ...mapGetters(['getPrices','getLicences']),
    ...mapMutations(['storeResume']),
          backPage(){
      this.$router.go(-1);
    
     

    },

paye(mode){

          this.is_paye= true;
         
          this.$store.state.acheteur.mode_paiement = mode;
         
          api.sendInscription(this.$store.state).then(res =>{
            this.storeResume(res.data);
            this.$router.push({ name: "Resume" });
          })
            

      }






  },
  created () {
    console.log(this.$store.state.licences);
//     if( this.$store.state.licences[0].constructor === Object){
// this.$store.state.licences.unshift(null);
//     }
   
  this.licences = this.getLicences();
  this.prices = this.getPrices();
  console.log(this.$store.state.licences);
 // this.licences.splice(0,1);
  
   console.log(this.licences);
console.log(this.$store.state.licences);
  }
  
  
}
</script>

<style lang="css">
  h1 {
    margin-bottom: 25px;
  }
  ul{
    margin-bottom: 16px;
  }
  a.v-btn{
    margin-top: 30px;
  }
  .hidden{
    display: none;
  }
</style>