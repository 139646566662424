import Axios from 'axios';


const RESOURCE_NAME = '/inscription';

export default {
  
    sendInscription(inscription) {
        return Axios.post(`${RESOURCE_NAME}`,inscription);
    },

    downloadCertificat(certificat) {
        return Axios.post(`${RESOURCE_NAME}/certificat`,certificat);
    }
    

};