<template>
  <v-container fluid >
    <v-row>
      <h1>Les adhérents</h1>
      <v-btn id="telecharger" class="text-right" color="blue darken-1" text :href="pathFile"  >Télécharger</v-btn>
      <v-btn class="text-right" color="blue darken-1" text @click="disconnect">Déconnection</v-btn>
    </v-row>
    <v-row>
      <v-col>
      <p>Nb adhérents : {{NbAdherents}}</p>
      <p>Nb adhérents avec Dossier Incomplet paye: {{NbAdherentsDossierNotCompletPaye}}</p>
      <p>Nb adhérents avec Dossier Incomplet Non paye: {{NbAdherentsDossierNotCompletNotPaye}}</p>
      <p>Nb adhérents avec Dossier Complet Non paye: {{NbAdherentsDossierCompletNotPaye}}</p>
      <p>Nb adhérents avec Dossier Complet paye: {{NbAdherentsDossierCompletPaye}}</p>
      <p>Nb adhérents Licencié: {{NbAdherentsLicencie}}</p>

      </v-col>
      
      
    
    </v-row>
    <v-row>
      <table>
        <thead>
          <tr>
            
            <td>Acheteur</td>
            <td>adhérent</td>
            <td>Email</td>
            <td>Téléphone</td>
            <td>Licence</td>
            <td>Prix du dossier</td>
            <td>Etat du dossier</td>
            <td>Licencié</td>
            <td>Mode de paiement</td>
            <td>Modifier</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="adherent in adherents" :key="adherent.id_adherent">
            
            <td>{{adherent.dossier.acheteur.name}} {{adherent.dossier.acheteur.firstname}}</td>
            <td>{{adherent.name}} {{adherent.firstname}}</td>
            <td>{{adherent.email}}</td>
            <td>{{adherent.phone}}</td>
            <td>{{adherent.price.Name.slice(adherent.price.Name.indexOf('+')+2)}}</td>
            <td>{{adherent.dossier.reduction_price}}</td>
            <td>
              <div @click="completAdh(adherent.dossier.id_dossier)"
              class="pointer"
                :class="{'complete': adherent.dossier && adherent.dossier.is_complete,'incomplet': adherent.dossier && !adherent.dossier.is_complete }"
              >{{adherent.dossier && adherent.dossier.is_complete ? "Dossier Complet":"Dossier Incomplet"}}</div> 
              <div 
                class="pointer"
              @click="payeAdh(adherent.dossier.id_dossier)"
                :class="{'complete': adherent.dossier && adherent.dossier.is_paye,'incomplet': adherent.dossier && !adherent.dossier.is_paye }"
              >{{adherent.dossier && adherent.dossier.is_paye ? "Dossier Payé":"Dossier Non Payé"}}</div>
            </td>
            <td>
              <div 
                class="pointer"
              @click="licencieAdh(adherent.id_adherents)"
                :class="{'complete': adherent && adherent.is_licencie,'incomplet': adherent && !adherent.is_licencie }"
              >{{adherent.is_licencie ? "Adhérent licencié":"Adhérent non licencié"}}</div>
            </td>
            <td>{{adherent.dossier.mode_paiement}}</td>
            <td class="text-center">
              <v-icon  small @click="modifyAdherent(adherent)">fas fa-edit</v-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="1200px">
        <v-card>
          <v-card-title>
            <span
              class="headline"
            >Dossier de {{adherent.dossier?adherent.dossier.acheteur.firstname:""}} {{adherent.dossier?adherent.dossier.acheteur.name:""}} (type de réglement :  {{adherent.dossier? adherent.dossier.mode_paiement:''}})</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span> </span>
            
            <v-btn
              :class="{'complete': adherent.dossier && adherent.dossier.is_complete,'incomplet': adherent.dossier && !adherent.dossier.is_complete }"
              color="blue darken-1"
              text
              @click="complet"
            >{{adherent.dossier && adherent.dossier.is_complete ? "Dossier Complet":"Dossier Incomplet"}}</v-btn>
            <v-btn
              :class="{'complete': adherent.dossier && adherent.dossier.is_paye,'incomplet': adherent.dossier && !adherent.dossier.is_paye }"
              color="blue darken-1"
              text
              @click="paye"
            >{{adherent.dossier && adherent.dossier.is_paye ? "Dossier Payé":"Dossier Non Payé"}}</v-btn>
            <v-btn
              :class="{'complete': adherent && adherent.is_licencie,'incomplet': adherent && !adherent.is_licencie }"
              color="blue darken-1"
              text
              @click="licencie"
            >{{adherent.is_licencie ? "Adhérent licencié":"Adhérent non licencié"}}</v-btn>
            <v-btn color="blue darken-1" text @click="closeDialog">Fermer</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-row id="Adult" class="text-center">
              <v-col cols="12">
                <h3>Informations du Licencié</h3>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Numéro de licence"
                      v-model="adherent.licence_number"
                      @change="searchByAdherent(adherent.licence_number)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="adherent.civilite" row>
                  <v-radio label="M." value="M."></v-radio>
                  <v-radio label="Mme" value="Mme"></v-radio>
                  <v-radio label="Mlle" value="Mlle"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Nom *"
                  v-model.trim="adherent.name"
                  @change="searchByName(adherent.name,adherent.firstname)"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field label="Nom de jeune fille" v-model="adherent.maiden"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Prénom *"
                  v-model="adherent.firstname"
                  @change="searchByName(adherent.name,adherent.firstname)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-radio-group v-model="adherent.sex" row>
                  <v-radio label="Masculin" value="H"></v-radio>
                  <v-radio label="Féminin" value="F"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="4">
                <!-- date picker in text  -->

                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="adherent.birthday"
                      label="Date de naissance *"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="adherent.birthday"
                    no-title
                    scrollable
                    @input="menu = false"
                    locale="fr"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">Annuler</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(adherent.birthday)">OK</v-btn>
                  </v-date-picker>
                </v-menu>

                <!-- fin du date picker -->
              </v-col>

              <v-col cols="4">
                <v-text-field label="Lieu de naissance *" v-model="adherent.lieu_naissance"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field label="Adresse *" v-model="adherent.address"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field label="Code postal *" v-model="adherent.postal_code"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field label="Ville *" v-model="adherent.city"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field label="Nationalité *" v-model="adherent.nationality"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Téléphone *" v-model="adherent.phone"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field label="Email *" v-model.lazy="adherent.email"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h3 v-if="!adherent.id_questionnaire">Certificat médical</h3>
                <h3 v-if="adherent.id_questionnaire">Questionnaire de santé QS-Sport</h3>
              </v-col>
              <v-col cols="12">
                <v-row v-if="adherent.id_questionnaire">
                  <v-col cols="12">
                    <p>Ce questionnaire de santé permet de savoir si vous devez fournir un certificat médical pour renouveler votre licence sportive.</p>
                  </v-col>
                  <v-template id="questionnaireAdherent" v-if="adherent.price.id != 3">
                  <v-col cols="12" :key="question.group" v-for="question in questions">
                    <h3>{{question.group}}</h3>
                    <v-col cols="12" >
                      <v-row  :key="q.ref" v-for="q in question.questions">
                        <v-col cols="3">
                          <v-radio-group v-model="adherent.questionnaire[q.ref]" row>
                            <v-radio label="Oui" value="1"></v-radio>
                            <v-radio label="Non" value="0"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="9" class="text-left">{{q.title}}</v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                  </v-template>
                   <v-template id="questionnaireAdherent"  v-if="adherent.price.id == 3">
                  <v-col cols="12" :key="question.group" v-for="question in questionsChild">
                    <h3>{{question.group}}</h3>
                    <v-col cols="12" >
                      <v-row  :key="q.ref" v-for="q in question.questions">
                        <v-col cols="3"> 
                          <v-radio-group v-model="adherent.questionnaire[q.ref]" row>
                            <v-radio label="Oui" value="1"></v-radio>
                            <v-radio label="Non" value="0"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="9" class="text-left">{{q.title}}</v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                  </v-template>
                  <v-col cols="3"></v-col>
                  <v-col cols="9" class="text-left">
                    <p>Nb : Les réponses formulées relèvent de la seule responsabilité du licencié</p>
                    <p>Un certificat médical doit être fourni si vous répondez "Oui" à une question</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <template>
                      <v-file-input
                        :rules="rules_files"
                        accept="image/*, .pdf"
                        label="Sélectionner votre certificat médical *"
                        v-model="adherent.certificat_input"
                      ></v-file-input>
                   </template>
                    <a v-if="adherent.certificat" :href= "'https://back.inscription.lesfousduvolant-quincy.fr/'+adherent.certificat.path+''">Voir le certificat médical</a>
                  </v-col>
                  <v-col cols="6">
                    
                    <!-- date picker in text  -->
                    <v-menu
                      ref="certificat"
                      v-model="certificat"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="adherent.date_certificat"
                          label="Date du certificat *"
                          persistent-hint
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="adherent.date_certificat"
                        no-title
                        scrollable
                        @input="certificat = false"
                        locale="fr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="certificat = false">Cancel</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.certificat.save(adherent.date_certificat)"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>

                    <!-- fin du date picker -->
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

             <v-row v-if="adherent.contacts && adherent.contacts.length>0"> 
              <v-col cols="12">
                <h3>Personnes à contacter</h3>
              </v-col>
              <v-col cols="12">
                <table >
                  <tbody>
                    <tr>
                      <td>
                        <v-text-field
                          label="Nom et prénom *"
                          v-model="adherent.contacts[0].name"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-select
                          :items="liens"
                          label="Lien de parenté *"
                          v-model="adherent.contacts[0].lien"
                        ></v-select>
                      </td>
                      <td>
                        <v-text-field
                          label="Numéro de téléphone *"
                          v-model="adherent.contacts[0].phone"
                        ></v-text-field>
                      </td>
                    </tr>
                   <tr>
                      <td>
                        <v-text-field
                          label="Nom et prénom "
                          v-model="adherent.contacts[1].name"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-select
                          :items="liens"
                          label="Lien de parenté "
                          v-model="adherent.contacts[1].lien"
                        ></v-select>
                      </td>
                      <td>
                        <v-text-field
                          label="Numéro de téléphone "
                          v-model="adherent.contacts[1].phone"
                        ></v-text-field>
                      </td>
                      <td></td>
                    </tr>
                     <tr>
                      <td>
                        <v-text-field
                          label="Nom et prénom "
                          v-model="adherent.contacts[2].name"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-select
                          :items="liens"
                          label="Lien de parenté "
                          v-model="adherent.contacts[2].lien"
                        ></v-select>
                      </td>
                      <td>
                        <v-text-field
                          label="Numéro de téléphone "
                          v-model="adherent.contacts[2].phone"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <v-text-field
                          label="Nom et prénom "
                          v-model="adherent.contacts[3].name"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-select
                          :items="liens"
                          label="Lien de parenté "
                          v-model="adherent.contacts[3].lien"
                        ></v-select>
                      </td>
                      <td>
                        <v-text-field
                          label="Numéro de téléphone "
                          v-model="adherent.contacts[3].phone"
                        ></v-text-field>
                      </td>
                    </tr> 
                  </tbody>
                </table>
              </v-col>
            </v-row> 
            <v-row v-if="adherent.contacts && adherent.contacts.length>0">
              <v-col cols="12">
                <h3>INFORMATIONS COMPLEMENTAIRES</h3>
              </v-col>
              <v-textarea
                solo
                label="Allergie,médicaments, ..."
                v-model="adherent.Informations_complementaires"
              ></v-textarea>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h3>INFORMATIONS COMPLEMENTAIRES</h3>
              </v-col>
              <v-col cols="12">
                <h3>Autorisations FFBad</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-checkbox
                v-model="adherent.info_utilise_ffbad"
                label="Dans l’intérêt du développement du badminton français, j’accepte que mes coordonnées puissent être utilisées par la fédération à des fins de communication (newsletters, mailing, ...)."
              ></v-checkbox> 
            </v-row>
            <v-row>
              <v-checkbox
                v-model="adherent.accept_fede_tiers"
                label="J’accepte que la fédération transmette mes coordonnées à ses partenaires afin notamment de recevoir des offres privilégiées et sélectionnées par la fédération. La liste des partenaires est disponible sur la page :
        https://www.ffbad.org/bas-de-page/partenaires/"
              ></v-checkbox>
            </v-row>
            <v-row>
              <v-checkbox
                v-model="adherent.resultat_accessible"
                label="Je souhaite que l'on puisse accéder à ma fiche de résultats. Dans le cas contraire, j’ai conscience que ma participation à au moins une compétition officielle me fera apparaitre automatiquement dans les résultats de compétition et dans le classement de la fédération conformément au règlement général des compétitions."
              ></v-checkbox>
            </v-row>
            <v-row>
              <p
                class="v-label theme--light text-left"
              >Dans le cadre de l’exécution de la présente prise de licence et pour la promotion de la FFBaD et du badminton, le signataire reconnait que la FFBaD et ses organes déconcentrés peuvent procéder à des captations d’image et de voix et à utiliser et diffuser, pour la promotion du badminton, les images et les voix ainsi captées, sur tous supports de communication quels qu’ils soient, à titre gratuit, et ce, pendant la durée de validité de sa licence, et pour le monde entier.</p>
              <v-checkbox
                v-model="adherent.photo_ffbad"
                label="J’atteste avoir pris connaissance des informations me concernant sur la gestion du droit à l’image effectuée par la Fédération et ses organes déconcentrés. *"
              ></v-checkbox>
            </v-row>

            <v-row>
              <h4 class="v-label theme--light">Garanties de l’Assurance Generali</h4>
            </v-row>
            <v-row>
              <a
                class="v-label theme--light"
                href="https://back.inscription.lesfousduvolant-quincy.fr/uploads/FFBAD_-_Formulaire_Assurance_2020-2021.pdf"
                target="_blanck"
              >Voir le document</a>
            </v-row>
            <v-row>
              <v-checkbox
                :class="{'error-message' :adherent.generali}"
                v-model="adherent.accept_generali"
                label="Je soussigné, atteste avoir été informé de l’intérêt que présente la souscription d’un contrat d’assurance de personne couvrant les dommages corporels auxquels la pratique sportive peut m’exposer (art. L321-4 du code du sport). Dans ce cadre, je déclare avoir reçu, pris connaissance et compris les modalités d’assurances présentées dans la notice d’information assurance FFBAD ci-annexée, et de la possibilité de souscrire à l’une des garanties d’assurance Accident Corporel facultatives proposées avec ma adherent. Le prix de l’option de base incluse dans ma licence est de 0,32€ TTC. Je peux ne pas y adhérer et le signifier par écrit auprès de mon club à l’aide du formulaire de refus mis à ma disposition (procédure obligatoire). La souscription d’une option complémentaire A ou B se fait à l’aide du bulletin d’adhésion individuelle téléchargeable depuis le site internet de la FFBAD - rubrique assurance. *"
              ></v-checkbox>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h3>Autorisations Club</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-checkbox
                v-model="adherent.photo_club_interne"
                label="J’autorise mon club à utiliser mon image pour promouvoir ses activités dans le cadre de ses locaux à titre gratuit et ce pendant la durée de validité de ma adherent."
              ></v-checkbox>
            </v-row>
            <v-row>
              <v-checkbox
                v-model="adherent.photo_club_externe"
                label="J’autorise mon club à utiliser mon image en dehors de ses locaux, sur tous supports de communication quels qu’ils soient, à titre gratuit, et ce, pendant la durée de validité de sa adherent."
              ></v-checkbox>
            </v-row>
            <v-row>
              <v-checkbox
                v-model="adherent.sms"
                label="J'accepte de recevoir par SMS les informations liées à la vie du club."
              ></v-checkbox>
           </v-row>
                 <v-row> 
              <v-textarea
                v-model="adherent.commentaire"
                label="Commentaire"
              ></v-textarea>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :class="{'complete': adherent.dossier && adherent.dossier.is_complete,'incomplet': adherent.dossier && !adherent.dossier.is_complete }"
              color="blue darken-1"
              text
              @click="complet"
            >{{adherent.dossier && adherent.dossier.is_complete ? "Dossier Complet":"Dossier Incomplet"}}</v-btn>
            <v-btn
              :class="{'complete': adherent.dossier && adherent.dossier.is_paye,'incomplet': adherent.dossier && !adherent.dossier.is_paye }"
              color="blue darken-1"
              text
              @click="paye"
            >{{adherent.dossier && adherent.dossier.is_paye ? "Dossier Payé":"Dossier Non Payé"}}</v-btn>
            <v-btn
              :class="{'complete': adherent && adherent.is_licencie,'incomplet': adherent && !adherent.is_licencie }"
              color="blue darken-1"
              text
              @click="licencie"
            >{{adherent.is_licencie ? "Adhérent licencié":"Adhérent non licencié"}}</v-btn>
            <v-btn color="blue darken-1" text @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>

import{mapMutations,mapActions} from 'vuex';
import api from '../../src/api/adherents.js';
import log from '../../src/api/login.js';
import adherents from '../../src/api/adherents.js';


export default {
  data: () => ({
      adherents:[],
      dialog: false,
      adherent:{
      commentaire:''
      },
      menu:false,
     certificat:false,
     pathFile:"",
     rules_files : [
       value => !value || value.size < 2000000 || 'La taille du fichier ne doit pas être supérieur à 2 MB!',

     ],
     liens : ['Père','Mère','Grand-parent','Amis de la famille','Voisin'],
     questions:
       [
         {group : 'Durant les 12 derniers mois',
         questions:[
        {
          title : '1) Un membre de votre famille est-il décédé subitement d’une cause cardiaque ou inexpliquée ?',
          ref:"Q1"
        },
         {
          title : '2) Avez-vous ressenti une douleur dans la poitrine, des palpitations, un essoufflement inhabituel ou un malaise ?',
          ref:"Q2"
        },
        {
          title : '3) Avez-vous eu un épisode de respiration sifflante (asthme) ?',
          ref:"Q3"
        },
        {
          title : '4) Avez-vous eu une perte de connaissance ?',
          ref:"Q4"
        },
        {
          title : '5) Si vous avez arrêté le sport pendant 30 jours ou plus pour des raisons de santé, avez-vous repris sans l’accord d’un médecin ? ',
          ref:"Q5"
        },
        {
          title : '6) Avez-vous débuté un traitement médical de longue durée (hors contraception et désensibilisation aux allergies) ?',
          ref:"Q6"
        }       

       ]        
         
         },
         {
           group: "A ce jour",
           questions: [
             {
          title : '7) Ressentez-vous une douleur, un manque de force ou une raideur suite à un problème osseux, articulaire ou musculaire (fracture, entorse, luxation, déchirure, tendinite, etc…) survenu durant les 12 derniers mois ?',
          ref:"Q7"
        },
        {
          title : '8) Votre pratique sportive est-elle interrompue pour des raisons de santé ?',
          ref:"Q8"
        },
        {
          title : '9) Pensez-vous avoir besoin d’un avis médical pour poursuivre votre pratique sportive ?',
          ref:"Q9"
        },
           ]
         }
       ],
       questionsChild:
       [
         {group : 'Durant les 12 derniers mois',
         questions:[
        {
          title : '1) Es-tu allé (e) à l\'hôpital pendant toute une journée ou plusieurs jours ?  ?',
          ref:"Q1"
        },
         {
          title : '2) As-tu été opéré (e) ? ',
          ref:"Q2"
        },
        {
          title : '3) As-tu beaucoup plus grandi que les autres années ? ',
          ref:"Q3"
        },
        {
          title : '4) As-tu beaucoup maigri ou grossi ? ',
          ref:"Q4"
        },
        {
          title : '5) As-tu eu la tête qui tourne pendant un effort ?  ',
          ref:"Q5"
        },
        {
          title : '6) As-tu perdu connaissance ou es-tu tombé sans te souvenir de ce qui s\'était passé ? ',
          ref:"Q6"
        }, 
        {
          title : '7) As-tu reçu un ou plusieurs chocs violents qui t\'ont obligé à interrompre un moment une séance de sport ?  ',
          ref:"Q7"
        },
        {
          title : '8) As-tu perdu connaissance ou es-tu tombé sans te souvenir de ce qui s\'était passé ? ',
          ref:"Q8"
        },
        {
          title : '9) As-tu eu beaucoup de mal à respirer pendant un effort par rapport à d\'habitude ?  ? ',
          ref:"Q9"
        },
        {
          title : '10) As-tu eu beaucoup de mal à respirer après un effort ?  ',
          ref:"Q10"
        },
        {
          title : '11) As-tu eu mal dans la poitrine ou des palpitations (le cœur qui bat très vite) ?  ',
          ref:"Q11"
        },
        {
          title : '12) As-tu commencé à prendre un nouveau médicament tous les jours et pour longtemps ?  ',
          ref:"Q12"
        },
        {
          title : '13) As-tu arrêté le sport à cause d\'un problème de santé pendant un mois ou plus ?  ',
          ref:"Q13"
        },      

       ]        
         
         },
         {
           group: " Depuis un certain temps (plus de 2 semaines) ",
           questions: [
             {
          title : '14) Te sens-tu très fatigué (e) ? ',
          ref:"Q14"
        },
        {
          title : '15) As-tu du mal à t\'endormir ou te réveilles-tu souvent dans la nuit ? ',
          ref:"Q15"
        },
        {
          title : '16) Sens-tu que tu as moins faim ? que tu manges moins ? ',
          ref:"Q16"
        },
        {
          title : '17) Te sens-tu triste ou inquiet ? ',
          ref:"Q17"
        },
        {
          title : '18) Pleures-tu plus souvent ?  ',
          ref:"Q18"
        },
        {
          title : '19) Ressens-tu une douleur ou un manque de force à cause d\'une blessure que tu t\'es faite cette année ?  ',
          ref:"Q19"
        },
           ]
         },
         {
           group: " Aujourd'hui ",
           questions: [
             {
          title : '20) Penses-tu quelquefois à arrêter de faire du sport ou à changer de sport ?  ',
          ref:"Q20"
        },
        {
          title : '21) Penses-tu avoir besoin de voir ton médecin pour continuer le sport ?  ',
          ref:"Q21"
        },
        {
          title : '22) Souhaites-tu signaler quelque chose de plus concernant ta santé ? ',
          ref:"Q22"
        },
       
           ]
         },
         {
           group: " Questions à faire remplir par tes parents  ",
           questions: [
             {
          title : '23) Quelqu\'un dans votre famille proche a-t-il eu une maladie grave du cœur ou du cerveau, ou est-il décédé subitement avant l\âge de 50 ans ?   ',
          ref:"Q23"
        },
        {
          title : '24) Etes-vous inquiet pour son poids ? Trouvez-vous qu\'il se nourrit trop ou pas assez ? ',
          ref:"Q24"
        },
        {
          title : '25) Avez-vous manqué l\'examen de santé prévu à l\'âge de votre enfant chez le médecin ? (Cet examen médical est prévu à l\'âge de 2 ans, 3 ans, 4 ans, 5 ans, entre 8 et 9 ans, entre 11 et 13 ans et entre 15 et 16 ans.)  ',
          ref:"Q25"
        },
       
           ]
         }
       ],
NbAdherents:'',
  NbAdherentsDossierNotCompletPaye:'',
  NbAdherentsDossierCompletNotPaye:'',
  NbAdherentsDossierNotCompletNotPaye:'',
  NbAdherentsDossierCompletPaye:'',
  NbAdherentsDossierComplete:'',
  NbAdherentsLicencie :'',
  }),
  

  methods : {
       ...mapMutations(['addAcheteur','storeResume']),
        backPage(){
      this.$router.go(-1);
      this.$store.state.backValue == true
    
     

    },  
    modifyAdherent(id){
      this.dialog = true;
      console.log(id);
     this.adherent = id ; 
     if(id.dossier && id.dossier.commentaire == null){
          id.commentaire=''
        }else{
          id.commentaire=id.dossier.commentaire 
        }
        this.adherent.info_utilise_ffbad = parseInt(this.adherent.info_utilise_ffbad)
        this.adherent.accept_fede_tiers = parseInt(this.adherent.accept_fede_tiers)
        this.adherent.resultat_accessible = parseInt(this.adherent.resultat_accessible)
        this.adherent.photo_ffbad = parseInt(this.adherent.photo_ffbad)
        this.adherent.accept_generali = parseInt(this.adherent.accept_generali)
        this.adherent.photo_club_interne = parseInt(this.adherent.photo_club_interne)
        this.adherent.photo_club_externe = parseInt(this.adherent.photo_club_externe)
        this.adherent.sms = parseInt(this.adherent.sms)

  },
  complet(){
    console.log('click complet');
    api.complete(this.adherent.dossier.id_dossier,this.$store.state.token).then(res => {
          this.adherent.dossier.is_complete = res.data.is_complete;
    });
      console.log('complete',this.adherent.dossier.is_complete);
  },
  completAdh(id){
    console.log(id);
    api.complete(id,this.$store.state.token).then(res => {
      this.closeDialog();
    });
      
  },
  
 
  paye(){
    api.paye(this.adherent.dossier.id_dossier,this.$store.state.token).then(res => {
      console.log('is payé',res)
          this.adherent.dossier.is_paye = res.data.is_paye;
    });
console.log('paye',this.adherent.dossier.is_paye);
  },
  payeAdh(id){
    api.paye(id,this.$store.state.token).then(res => {
          this.closeDialog();
    });
// console.log('paye',this.adherent.dossier.is_paye);
  },
  licencie(){
api.licencie(this.adherent.id_adherents,this.$store.state.token).then(res => {
          this.adherent.is_licencie = res.data.is_licencie;
    });
    console.log('paye',this.adherent.is_licencie);
  },
   licencieAdh(id){
api.licencie(id,this.$store.state.token).then(res => {
          this.closeDialog();
    });
    console.log('paye',this.adherent.is_licencie);
  },
  closeDialog(){
    api.list(this.$store.state.token).then(res =>{
      console.log(res.data);
        this.adherents = res.data.adherents;
         res.data.adherents.forEach(licencie => {
        console.log(licencie.dossier.is_paye,parseInt(licencie.dossier.is_paye));
        licencie.dossier.is_paye = parseInt(licencie.dossier.is_paye);
        licencie.dossier.is_complete = parseInt(licencie.dossier.is_complete);
        licencie.is_licencie = parseInt(licencie.is_licencie);
        // licencie.adherent.info_utilise_ffbad = parseInt(licencie.adherent.info_utilise_ffbad);
        // licencie.is_licencie = parseInt(licencie.is_licencie);
        // licencie.is_licencie = parseInt(licencie.is_licencie);

          for( var i=0; i<4;i++){
          if(licencie.contacts.length >0){
            console.log(licencie.contacts.length-1, 'longuerur contacts')
            if(i < licencie.contacts.length-1){
                licencie.contacts[i] = licencie.contacts[i]
            }else{
               licencie.contacts[i] = {name:"",lien:"",phone:''}
            }
          }
        }
      });
      this.adherent = res.data;
       this.NbAdherents = res.data.NbAdherents.count
        this.NbAdherentsDossierComplete = res.data.NbAdherentDossierCompleter.count
        this.NbAdherentsDossierCompletNotPaye = res.data.NbAdherentsDossierCompleteNotPaye.count
        this.NbAdherentsDossierCompletPaye = res.data.NbAdherentsDossierCompleterPaye.count
        this.NbAdherentsDossierNotCompletPaye = res.data.NbAdherentsDossierNotCompletePaye.count
        this.NbAdherentsDossierNotCompletNotPaye = res.data.NbAdherentsDossierNotCompleteNotPaye.count
        this.NbAdherentsLicencie = res.data.NbAdherentsLicencie.count
        this.dialog = false;
         this.downloadFile();
    })
  },

  saveData(){
    
    this.adherent.dossier.commentaire = this.adherent.commentaire;
    console.log(this.adherent);
    api.update(this.adherent.id_adherents,{'adherent':this.adherent},this.$store.state.token).then(res =>{
      if(licence.licence.licencie.certificat != undefined && licence.licence.licencie.certificat != ""){
        data.append("certificat", licence.licence.licencie.certificat);
       var uniqId = Date.now();
       data.append("ref",uniqId );
       inscription.downloadCertificat(data).then (res => {
         console.log(res.data.id,res.data, 'after download file');
         licence.licence.licencie.ref_certif = res.data;
         console.log( licence.licence.licencie);
       });

       }
      this.closeDialog()
    })

  },
  disconnect(){
    log.disconnect(this.$store.state.token).then(res =>{
      this.$router.push({ name: "Login" });
    });
    
  },
  downloadFile(){
    api.downloadFile(this.$store.state.token).then(res => {
      this.pathFile = res.data;
     
    })
  }


  },

  

  created(){
    if(!this.$store.state.token){
      this.$router.push({ name: "Login" });
    }
    api.list(this.$store.state.token).then(res =>{
      console.log(res.data);
      res.data.adherents.forEach(licencie => {
          console.log(licencie.dossier.is_paye,parseInt(licencie.dossier.is_paye));
        licencie.dossier.is_paye = parseInt(licencie.dossier.is_paye);
        licencie.dossier.is_complete = parseInt(licencie.dossier.is_complete);
        if(licencie.dossier && licencie.dossier.commentaire == null){
          licencie.dossier.commentaire=''
        }
        licencie.is_licencie = parseInt(licencie.is_licencie);

        for( var i=0; i<4;i++){
          if(licencie.contacts.length >0){
            console.log(licencie.contacts.length-1, 'longuerur contacts')
            if(i < licencie.contacts.length-1){
                licencie.contacts[i] = licencie.contacts[i]
            }else{
               licencie.contacts[i] = {name:"",lien:"",phone:''}
            }
          }
        }

      });
        
        

        this.adherents = res.data.adherents;
        this.downloadFile();
        this.NbAdherents = res.data.NbAdherents.count
        this.NbAdherentsDossierComplete = res.data.NbAdherentDossierCompleter.count
        this.NbAdherentsDossierCompletNotPaye = res.data.NbAdherentsDossierCompleteNotPaye.count
        this.NbAdherentsDossierCompletPaye = res.data.NbAdherentsDossierCompleterPaye.count
        this.NbAdherentsDossierNotCompletPaye = res.data.NbAdherentsDossierNotCompletePaye.count
        this.NbAdherentsDossierNotCompletNotPaye = res.data.NbAdherentsDossierNotCompleteNotPaye.count
        this.NbAdherentsLicencie = res.data.NbAdherentsLicencie.count

    }).catch(err => {
      console.log('err',err);
      this.$router.push({ name: "Login" });
    })
   

    
  }


}

</script>

<style scoped lang="css">
.incomplet >>> span
{
  color: red;
}
div.incomplet{
  background-color: red;
  color: white;
  padding: 5px;
  text-align: center;
  font-weight: bold;
} 
div.complete{
  background-color: green;
  color: white;
  padding: 5px;
  text-align: center;
  font-weight: bold;
} 
.complete >>> span {
  color: green;
}
table th:nth-child(5),table td:nth-child(5){
  width: 240px;
}
h1{
  width: 80%;
}
.container{
  margin: 0px 0 0px 15px;
  width: 99%;
}
#telecharger{
  margin-top: 0;
}
.pointer {
  cursor: pointer;
  margin:5px 0
}
table{
  border-collapse:collapse
}
table tr{
  border-bottom: 1pt solid black
}
table tr td {
	padding: 0 50px 0px 0;
}
#questionnaireAdherent .text-left{
  align-items: center;
display: flex;
}
.v-date-picker-table{
  height: 100%!important;
}
</style>