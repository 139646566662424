<template>
  <v-container>

      <v-row>
        <h1>Se connecter</h1>
      </v-row>
          <v-form>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field v-model="email" label="Email"></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Mot de passe"
            
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
        <v-col cols="12" >
        <p class="error-message" v-if="error">Le mot de passe ou le login est erroné</p>
        </v-col>
      </v-row>
    </v-form>
     <v-row class="text-left">
      <v-col cols="12">
        <v-btn @click="send" large color="primary">Valider</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Form from '@/components/FormStepOne.vue'
import api from '../../src/api/login.js';
import{mapMutations} from 'vuex';
export default {
  name: 'Home',
 data: () => ({
     show1:false,
     email:"",
     password:"",
     error : false
 }),
 methods : {
     ...mapMutations(['saveToken']),
     send(){
            this.error = false;
         api.login({'email': this.email,'password': this.password}).then(res =>{
            this.saveToken(res.data.remember_token);
           this.$router.push({ name: "Adherents" });

                console.log(this.$store.state.token);
         }).catch(err =>{
          // console.log(err.response);
             if(err.response.status == 400){
                 this.error = true;
                 console.log(this.error)
             }
         })
         
     }
 }
  
  
}
</script>