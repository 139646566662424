var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v("Bienvenue sur le site d'inscription des Fous du Volant de Quincy/Sénart")]),_c('h2',[_vm._v("Pour chaque catégorie, veuillez sélectionner le nombre de licences désirées")])])])],1),_c('v-row',{staticClass:"text-center",attrs:{"id":"Adult"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"licence"},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":[{
        text: 'Libellé',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Prix unitaire',
        align: 'start',
        sortable: false,
        value: 'price',
      },
      {
        text: 'Quantité',
        align: 'start',
        sortable: false,
        value: 'quantity',
      },
      {
        text: 'Montant',
        align: 'start',
        sortable: false,
        value: 'amount',
      }],"items":_vm.licences,"item-key":"id"},scopedSlots:_vm._u([{key:"item.quantity",fn:function({ item }){return [(item.slug != 'total'  )?_c('v-select',{attrs:{"items":_vm.choice,"label":"Nombre de licences"},on:{"change":function($event){return _vm.amount_calcul(item.quantity,item.price,item.id)}},model:{value:(item['quantity']),callback:function ($$v) {_vm.$set(item, 'quantity', $$v)},expression:"item['quantity']"}}):_vm._e()]}},{key:"item.price",fn:function({ item }){return [(item.slug != 'total'  )?_c('span',[_vm._v(_vm._s(item.price)+" €")]):_vm._e()]}},{key:"item.amount",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.amount)+" €")])]}}])})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-left"},[_vm._v(" * Licence Jeune ou MiniBad")])])],1),_c('v-row',{staticClass:"text-right"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":_vm.counterLicence}},[_vm._v("Suivant")])],1)],1),_c('v-row',[_c('p',{staticClass:"text-center"},[_vm._v("Le calcul des différentes réductions auxquelles vous pouvez prétendre se fait automatiquement. Vous pourrez les consulter avant la validation définitive de la commande")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }