import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Inscription from '../views/Inscriptions.vue'
import Acheteur from '../views/Acheteur.vue'
import Resume from '../views/Resume.vue'
import Payment from '../views/Payment.vue'
import Adherents from '../views/Adherents.vue'
import Login from '../views/Login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta : {
      title : 'Accueil'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/inscription/:id',
    component: Inscription,
    name : 'Inscription',
    props:true,
    meta : {
      title : 'Nouvel adhérent'
    }
    
  },
  {
    path: '/acheteur',
    component: Acheteur,
    name : 'Acheteur',
    props:false,
    meta : {
      title : 'Acheteur'
    }  
  }, 
  {
    path: '/resume',
    component: Resume,
    name : 'Resume',
    props:false,
    meta : {
      title : 'Confirmation de commande'
    }  
  },
  {
    path: '/paiement',
    component: Payment,
    name : 'Payment',
    props:false,
    meta : {
      title : 'Paiement'
    }  
  },

  {
    path: '/adherents',
    component: Adherents,
    name : 'Adherents',
    props:false,
    meta : {
      title : 'Adherents'
    }  
  },
  {
    path: '/login',
    component: Login,
    name : 'Login',
    props:false,
    meta : {
      title : 'Se connecter'
    }  
  },
]



















const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  
  
})
// router.beforeEach((to,from,next) => {
//   document.title = to.meta.title
// })
export default router
