<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="title">
          <h1>Bienvenue sur le site d'inscription des Fous du Volant de Quincy/Sénart</h1>

          <h2>Pour chaque catégorie, veuillez sélectionner le nombre de licences désirées</h2>
        </div>
      </v-col>
    </v-row>
    <v-row id="Adult" class="text-center">
      <v-col cols="12">
        <div class="licence">
          <v-data-table
            hide-default-footer
            :headers="[{
          text: 'Libellé',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Prix unitaire',
          align: 'start',
          sortable: false,
          value: 'price',
        },
        {
          text: 'Quantité',
          align: 'start',
          sortable: false,
          value: 'quantity',
        },
        {
          text: 'Montant',
          align: 'start',
          sortable: false,
          value: 'amount',
        }]"
            :items="licences"
            item-key="id"
          >
            <template v-slot:item.quantity="{ item }">
              <v-select
                v-if="item.slug != 'total'  "
                :items="choice"
                label="Nombre de licences"
                v-model="item['quantity']"
                @change="amount_calcul(item.quantity,item.price,item.id)"
              ></v-select>
            </template>
            <template v-slot:item.price="{ item }">
              <span v-if="item.slug != 'total'  ">{{item.price}} &euro;</span>
            </template>
            <template v-slot:item.amount="{ item }">
              <span >{{item.amount}} &euro;</span>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
     <v-row>
        <v-col cols="12">
      <p class="text-left"> * Licence Jeune ou MiniBad</p>
        </v-col>
    </v-row>
    <v-row class="text-right">
      <v-col cols="12">
        <v-btn @click="counterLicence" large color="primary">Suivant</v-btn>
        <!--  -->
      </v-col>
    </v-row>
   
    <v-row>
      <p
        class="text-center"
      >Le calcul des différentes réductions auxquelles vous pouvez prétendre se fait automatiquement. Vous pourrez les consulter avant la validation définitive de la commande</p>
    </v-row>
  </v-container>
</template>   


<script>
import{mapMutations,mapActions} from 'vuex'
import prices from '../../src/api/prices.js';
export default {
  data: () => ({
    choice: [0,1, 2, 3, 4, 5],
    amount_adulte_entrainement: 0,
    amount: {
      

      total:0
    },
    quantity: {
      
    },
    prices :{
        adult : [],
        child : []
    },
    licences : [],
    total :0

    
  }),

  methods: {
    amount_calcs(type, price, quantity,name,id) {
     console.log(type, price, quantity,name,id)
      this.amount[type] = price * quantity;
      
      console.log(price * quantity,type,this.amount[type])
    var total = 0;
    
        for( const property in this.amount){
            if(property != 'total' && property != 'ANCIEN_ADHERENT'){
                total =total + this.amount[property];
                if(property == type){

                    this.quantity[property] = quantity;
                }
                console.log('totalllll',total,property);
            }
        
        }
        this.amount.total = total
    

     // this.amount.total = this.amount.adulte_entrainement * this.quantity.adulte_entrainement + this.amount.adulte_loisir * this.quantity.adulte_loisir + this.amount.enfant_entrainement * this.quantity.adulte_loisir + this.amount.enfant_loisir * this.quantity.adulte_loisir;
      
      
      console.log(this.amount[type],type)
      return this.amount[type];
    },


    amount_calcul(quantity,price,id){
          var  total = 0;
        this.licences.forEach(licence => {
            if(licence.slug != 'total'){
                 
                licence.amount = licence.quantity*licence.price
                total += licence.quantity*licence.price
               
            }
        });
        this.total = total;
                this.licences[this.licences.length - 1].amount = total
        console.log(total, 'total by line')


    },
    total_cal(){
               var  total = 0;
        this.licences.forEach(licence => {
         
            if(licence.slug != 'total'){
                
                 total += licence.amount
                  
            }
        });
        
                this.licences[this.licences.length - 1].amount = total
        console.log(total, 'total calcul')
    },








    ...mapMutations(['addLicence']),
    ...mapActions(['countLicence']),
    counterLicence(){
      var dossier = [];
        var quantity = {};
        console.log('this.quantity',this.quantity);
        this.licences.forEach(element => {
          console.log(element);
            if(element.slug!= 'total'){
       quantity[element.slug]= element.quantity;
       dossier.push({
         'price': element.price, 'slug' : element.slug,'name' : element.name,'id':element.id,'quantity' : element.quantity
       })
            }
     
            
        });
     console.log(dossier)
      this.countLicence(quantity);
       this.addLicence(dossier);
     // console.log(this.$store.state);
     this.$router.push({ name: "Inscription", params: { id: 1 } });


 
      
  }
  },
  computed : {

  },
  created(){
     localStorage.clear();
       this.$store.replaceState(  {licence: {
     
    },
    counter:{},
    licences:[],
    acheteur : {},
    dossier: {
      
    },
    resume:{},
    certificat:[],
    back:false,
    componentKey:0,
    indexRoute:1


  });


    prices.getPrice().then(prices => {
         console.log(prices);
         
        prices.data.forEach(price => {

        if(price.slug.includes('ENFANT') == true){

                        this.licences.push( {'price': price.Price, 'slug' : price.slug,'name' : price.Name,'id':price.id,
                        'quantity':this.$store.state.counter[price.slug]? this.$store.state.counter[price.slug] :0,'amount' : 0,'etat':'enfant'} );
                        this.amount_calcul(this.$store.state.counter[price.slug]? this.$store.state.counter[price.slug] :0,price.Price,price.id);


        }
        if(price.slug.includes('ADULTE') == true){


                this.licences.push( {'price': price.Price, 'slug' : price.slug,'name' : price.Name,'id':price.id,
                'quantity':this.$store.state.counter[price.slug]? this.$store.state.counter[price.slug] :0,'amount' : 0,'etat':'adult'} );
                
                this.amount_calcul(this.$store.state.counter[price.slug]? this.$store.state.counter[price.slug] :0,price.Price,price.id);

            }
                

        });

      this.licences.push( {'price': '', 'slug' : 'total','name' : 'Total','id':0,
                'quantity':'','amount' : 0,'etat':'total'} );

                this.total_cal();

        console.log(this.licences,'created this.prices');

       
        
    })


    //   this.quantity.adulte_entrainement = this.$store.state.counter.adulte_entrainement?this.$store.state.counter.adulte_entrainement:0;
    //   this.quantity.adulte_loisir = this.$store.state.counter.adulte_loisir?this.$store.state.counter.adulte_loisir:0;
     
    //   this.amount_calcs('adulte_entrainement',150,this.quantity.adulte_entrainement);
    //   this.amount_calcs('adulte_loisir',120,this.quantity.adulte_loisir);
  }
  
};
</script>

<style scoped>
h1 {
  margin-bottom: 50px;
  line-height: 50px;
}
h2 {
  margin-bottom: 30px;
}
button.v-btn{
  margin-bottom: 30px;
}
</style>