<template>
  <v-container>
      <v-col class="text-left" cols="12" sm="4">
            <div class="my-2">
              <v-btn @click="backPage" color="primary" rounded>Retour</v-btn>
            </div>
          </v-col> 
    <h1>Acheteur</h1>
    <v-row>
      <v-snackbar v-model="errors" :top="true" :color="'red'">
        <p v-html="messages_txt"></p>
      </v-snackbar>
    </v-row>
    <v-row>
      <p>Veuillez renseigner les coordonnées de la personne effectuant le paiement de la (ou des) cotisation(s) :</p>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field label="Nom *" v-model="$v.acheteur.name.$model"></v-text-field>
        <p class="error-message" v-if="$v.acheteur.name.$anyError">Le champ est obligatoire</p>
      </v-col>
      <v-col cols="6">
        <v-text-field label="Prénom *" v-model="$v.acheteur.firstname.$model"></v-text-field>
        <p class="error-message" v-if="$v.acheteur.firstname.$anyError">Le champ est obligatoire</p>
      </v-col>
      <v-col cols="6">
        <v-text-field label="Téléphone *" v-model="$v.acheteur.phone.$model"></v-text-field>
        <p class="error-message" v-if="$v.acheteur.phone.$anyError">Le champ est obligatoire</p>
      </v-col>
      <v-col cols="6">
        <v-text-field label="Email *" v-model.lazy="acheteur.email" @input="delayTouch($v.acheteur.email)"></v-text-field>
        <p class="error-message" v-if="!$v.acheteur.email.required&& $v.acheteur.email.$anyError">Le champ est obligatoire</p>
        <p class="error-message" v-if="!$v.acheteur.email.email&& $v.acheteur.email.$anyError">Merci de mettre un email valide</p>
      </v-col>

       
    </v-row>

    <v-row>
      <v-col cols="12"
        
        class="text-right"
        >
              <div class="my-2">
      <v-btn @click="paye('')" large color="primary">Suivant</v-btn>
              </div>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>

import{mapMutations,mapActions} from 'vuex';
import api from '../../src/api/inscription.js';
import { required, requiredUnless,sameAs,email } from 'vuelidate/lib/validators'
const touchMap = new WeakMap()
export default {
  data: () => ({
      acheteur : {
          name:"",
          firstname:"",
          phone:"",
          email:"",
          mode_paiement:""
      },
       errors:false,
    messages_txt:'',
   

  }),
  validations:{
    acheteur : {
              name: {
                required
              },
              firstname:{
                required
              },
              phone:{
                required
              },
              email:{
                required,email
              },
          },
   
  },
  methods : {
       ...mapMutations(['addAcheteur','storeResume']),
        backPage(){
      this.$router.go(-1);
      this.$store.state.backValue == true
    
     

    },
    delayTouch($v) {
      console.log($v,'$v');
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
      paye(mode){


          this.errors = false;
         

          this.$v.$touch()
            if (this.$v.$invalid ){
            console.log('erreur')
              this.$vuetify.goTo(0)
            this.errors=true;
            this.messages_txt = "Le ou les champs suivants ne sont pas remplis : <br/>"
            if(this.$v.acheteur.name.$error){
              this.messages_txt += "- nom <br/>"
            }
            if(this.$v.acheteur.firstname.$error){
              this.messages_txt += "- prénom <br/>"
            }
            if(this.$v.acheteur.phone.$error){
              this.messages_txt += "- téléphone <br/>"
            }
            if(!this.$v.acheteur.email.required){
              this.messages_txt += "- email <br/>"
            } 
            if(!this.$v.acheteur.email.email){
              this.messages_txt += "- format de l'email non valide <br/>"
            }

            }else{
          console.log(this.$store.state.licences,'licences send acheteur');
          this.addAcheteur(this.acheteur);
              console.log(this.$store.state.licences,'licences after add acheteur '); 
          this.$router.push({ name: "Payment" });
            }

      }


       
  },

  created(){
    console.log(!(Object.keys(this.$store.state.acheteur).length === 0),this.$store.state.licences,);
    if(!(Object.keys(this.$store.state.acheteur).length === 0)){
      let  acheteur =  this.$store.state.acheteur;
      console.log(acheteur);
      this.acheteur.name = acheteur.name;
      this.acheteur.firstname = acheteur.firstname;
      this.acheteur.phone = acheteur.phone;
      this.acheteur.email = acheteur.email;
    }
    

  }


}

</script>