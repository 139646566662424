import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`
  window.scrollTo(0, 0)
  
  next()
});



Vue.config.productionTip = false;
Vue.use(Vuelidate);
new Vue({
  router,
  store,
  vuetify,

  beforeCreate() {
		this.$store.commit('initialiseStore');
	},
  render: h => h(App)
}).$mount('#app')
