<template>
 <v-container>
    <v-row class="text-center">
      <v-col cols="12">
          <div class="home">           
            <Form />            
          </div>
       
      </v-col>

      
    </v-row>

    
    
  </v-container>
 
</template>

<script>
// @ is an alias to /src
import Form from '@/components/FormStepOne.vue'

export default {
  name: 'Home',
  components: {
    Form
  },
  
  
}
</script>
