<template>
 <v-container>
    <v-row class="text-center">
      <v-col cols="12">
          <div class="resume">           
                  <h1>Confirmation d'inscription</h1>

                  <p>Merci {{ acheteur.fullname}} pour l'inscription de : </p>
                  <ul>
                    <li v-for="(adherent,index) in adherents" :key="index">{{adherent.firstname}} {{adherent.name}} </li>
                  </ul>

              

                <p> Vous avez bénéficié des remises : </p>
                    <ul>
                      <li v-for="(reduction,i) in reductions.reductions" :key="i">  {{reduction}} {{i}} </li>
                    </ul>

                    <p> Soit une réduction de :<strong> {{Math.abs(dossier.reduction)}} &euro; </strong> </p>

                <p>  Le prix de la commande s'élève donc à <strong> {{dossier.price}} &euro;</strong> </p>

                 <p> La commande est enregistée sous la référence <strong> {{ acheteur.name}} {{ acheteur.firstname}} </strong> </p>

                 <p> Nous avons envoyé un mail à <strong>{{acheteur.email}}</strong> pour confirmer la commande et préciser les modalités de paiement. </p>

                            <v-btn href="http://lesfousduvolant-quincy.fr" large color="primary">Retour vers le site</v-btn>
          </div>
       
      </v-col>

      
    </v-row>
    
  </v-container>
 
</template>

<script>
// @ is an alias to /src

import {mapGetters} from  'vuex';
export default {
  name: 'Resume',
  data : () => ({
      acheteur:{},
      dossier : {},
      adherents : {},
      reductions : []
  }),
  components: {
    
  },
  
  methods : {
    ...mapGetters(['getAcheteur','getAdherents','getDossier','getReductions']),
  },
  created () {
    if(Object.keys(this.$store.state.acheteur).length === 0 ){
      this.$router.push({ name: "Home" });
    }else{
  //  console.log(this.$store.state)
  this.acheteur = this.getAcheteur();          
  this.dossier = this.getDossier();
  this.reductions = this.getReductions();
  this.adherents = this.getAdherents().adherent;
   
   localStorage.clear();
    }
  }
  
  
}
</script>

<style lang="css">
  h1 {
    margin-bottom: 25px;
  }
  ul{
    margin-bottom: 16px;
  }
  a.v-btn{
    margin-top: 30px;
  }
</style>