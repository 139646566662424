import Axios from 'axios';


const RESOURCE_NAME = '/prices';

export default {
  
    getPrice() {
        return Axios.get(`${RESOURCE_NAME}`);
    }
    

};