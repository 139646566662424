import Axios from 'axios';


const RESOURCE_NAME = '';

export default {
  
    login(data) {
        return Axios.post(`login`,data);
    },

    disconnect(token){
        return Axios.post(`logout?token=`+token,"");
    }
    

};