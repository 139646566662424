import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    licence: {
     
    },
    counter:{},
    licences:[],
    acheteur : {},
    dossier: {},
    resume:{},
    certificat:[],
    back:false,
    componentKey:0,
    indexRoute:1,
    token:'zDMT2OXpLZF8zw9Iyvlk4pPEL0aKExzZ2Hlof2h4eIDiWUyFXwuvgdJGeGFt'

  },
  mutations: {
    addLicence(state,objLicence){
      state.licence= objLicence;
      localStorage.setItem('licence', JSON.stringify(state.licence));
    },
   
    minusCount(state,count){
      console.log(count,state,'minus count');
      state.counter[count] -=1;
    
      localStorage.setItem('counter', JSON.stringify(state.counter));
    },
    addOneLicence(state,licence){
      console.log(licence);
      state.licences[licence.index] = licence.licence;
      localStorage.setItem('licences', JSON.stringify(state.licences));
     
    }, 
    addCertificat(state,certificat){
      console.log(certificat);
      state.licences.push(certificat.value);
     
    },
    addCountLicence(state,counter){
      console.log(state.counter, 'before update');
      state.counter = counter;
      state.dossier = counter;
     console.log(state.dossier,'after')
      localStorage.setItem('counter', JSON.stringify(state.counter));
    },
    back(state,value){
        state.back = value;
        localStorage.setItem('back', JSON.stringify(state.back));
    },
    componentKeyValue(state,value){
        state.componentKey = value;
        localStorage.setItem('componentKey', JSON.stringify(state.componentKey));
    },
    paramRoute(state,value){
        state.indexRoute = value;
    },
    saveToken(state,token){
      state.token = token;
      localStorage.setItem('token', state.token);
    },
    initialiseStore(state) {
      // Check if the ID exists
      console.log(state.licences,'before initialise store');
			if(localStorage.getItem('counter')) {
				// Replace the state object with the stored item
				
					state.counter =JSON.parse(localStorage.getItem('counter'));
			
      }
      if(localStorage.getItem('licence')) {
				// Replace the state object with the stored item
				
					state.licence =JSON.parse(localStorage.getItem('licence'));
			
      }
      if(localStorage.getItem('licences')) {
				// Replace the state object with the stored item
				
					state.licences =JSON.parse(localStorage.getItem('licences'));
			
      }
      if(localStorage.getItem('acheteur')) {
				// Replace the state object with the stored item
				
					state.acheteur =JSON.parse(localStorage.getItem('acheteur'));
			
      }	
      if(localStorage.getItem('resume')) {
				// Replace the state object with the stored item
				
					state.resume =JSON.parse(localStorage.getItem('resume'));
			
      } 
      if(localStorage.getItem('back')) {
				// Replace the state object with the stored item
				
					state.back =JSON.parse(localStorage.getItem('back'));
			
      }
      if(localStorage.getItem('componentKey')) {
				// Replace the state object with the stored item
        
					state.componentKey =JSON.parse(localStorage.getItem('componentKey'));
			
      }
      if(localStorage.getItem('indexRoute')) {
				// Replace the state object with the stored item
        
					state.indexRoute =JSON.parse(localStorage.getItem('indexRoute'));
			
      }
      if(localStorage.getItem('token')) {
				// Replace the state object with the stored item
        
					state.token =localStorage.getItem('token');
			
      }
      console.log('state after initialise store',this.state);
    },
    addAcheteur(state,acheteur){
      state.acheteur = acheteur;
      localStorage.setItem('acheteur', JSON.stringify(state.acheteur));
    },
    storeResume(state,resume){
      state.resume = resume;
      localStorage.setItem('resume', JSON.stringify(state.resume));
    }
  },
  actions: {
    countLicence(store,counter){
      //  console.log(counter);
      store.commit('addCountLicence',counter)
      
       // console.log(state.counter);
      },
  


  },
  getters :{
    getAcheteur(state){
      return {'fullname': state.resume.acheteur.firstname + ' ' + state.resume.acheteur.name, 'email' : state.resume.acheteur.email,'name': state.resume.acheteur.name,'firstname': state.resume.acheteur.firstname}
    } ,
    getDossier(state){
      console.log(state.resume.dossier.dossier.reduction_price, 'getdossier');
      return {'price': state.resume.dossier.dossier.reduction_price , 'ref_dossier' : state.resume.dossier.dossier.ref_dossier,'reduction': state.resume.dossier.dossier.reduction};
    }, 
    getAdherents(state){
      return {'adherent': state.resume.adherents};
    },
    getReductions(state){
      console.log(state.resume.reductions, 'getreductions');
      return {'reductions': state.resume.reductions};
    },
    getLicences(state){
      return state.licences;
    },
    getPrices(state){
      console.log(state.licence,'getprices');
      return state.licence;
    }
  },
  modules: {
  }
})
