<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="home">
          <v-col class="text-left" cols="12" sm="4">
            <div class="my-2">
              <v-btn @click="backPage" color="primary" rounded>Retour</v-btn>
            </div>
          </v-col>
          <Adult
            v-if="
              (this.adulteForm &&
                this.$store.state.counter['ADULTE_ENTRAINEMENT'] > 0) ||
              (licence.name != '' && licence.type == 'ADULTE_ENTRAINEMENT')
            "
            :type="'ADULTE_ENTRAINEMENT'"
            :licence="licence"
            :backValue="backValue"
            @send="licencePass($event)"
            @numLicence="SearchByLicence($event)"
            @searchByName="SearchByName($event)"
            @parseDate="parseDate($event)"
            :key="componentKey"
          />
          <Adult
            v-if="
              (this.adulteForm &&
                this.$store.state.counter['ADULTE_ENTRAINEMENT'] <= 0 &&
                this.$store.state.counter['ADULTE_LOISIR']) ||
              (licence.name != '' && licence.type == 'ADULTE_LOISIR')
            "
            :type="'ADULTE_LOISIR'"
            :licence="licence"
            @send="licencePass($event)"
            @numLicence="SearchByLicence($event)"
            @searchByName="SearchByName($event)"
            @parseDate="parseDate($event)"
            :key="componentKey"
          />
          <!-- <Child
            v-if="(!this.adulteForm && this.childForm && this.$store.state.counter['ENFANT_ENTRAINEMENT'] >0) || (licence.name != '' &&  licence.type == 'ENFANT_ENTRAINEMENT')"
            :type="'ENFANT_ENTRAINEMENT'"
            :licence="licence"
            @send="licencePass($event)"
            @numLicence="SearchByLicence($event)"
            @searchByName="SearchByName($event)"
            @parseDate="parseDate($event)"
            :key="componentKey"
          />-->
          <Child
            v-if="
              (!this.adulteForm &&
                this.childForm &&
                this.$store.state.counter['ENFANT'] > 0) ||
              (licence.name != '' && licence.type == 'ENFANT')
            "
            :type="'ENFANT'"
            :licence="licence"
            @send="licencePass($event)"
            @numLicence="SearchByLicence($event)"
            @searchByName="SearchByName($event)"
            @parseDate="parseDate($event)"
            :key="componentKey"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Adult from '@/components/FormAdulte.vue';
import Child from '@/components/FormChild.vue';
import{mapMutations} from 'vuex';
import adherent from '../../src/api/adherents.js';
import inscription from '../../src/api/inscription.js';
export default {
  data: () => ({
      componentKey:0,
      licence : {
        licencie:{
          renouvellement:false,
          name:"",
          firstname:"",
          civilite:"",
          maiden:"",
          dateofbirth:new Date().toISOString().substr(0, 10),
          lieu_naissance:"",
          dateofcertificat:new Date().toISOString().substr(0, 10),
          nationality:"Français",
          address:"",
          postal_code:"",
          city:"",
          phone:"",
          email:"",
          renouvellement_moins_3ans:true,
          questionnaire:{},
          num_licence:"",
          info_utilise_ffbad : false,
          accept_fede_tiers :false,
          result_accessible :false,
          photos_ffbad:false,
          photos_club_interne:true,
          photos_club_externe:true,
          sms:true,
          child:{},
          generali:false   

        },
       
        club:{
          ligue:"Île de france",
          departement:"Essonne",
          club:"Les Fous du Volant"
        },
        
      },
      adulteForm : false,
        childForm : false,
        backValue : false

  }),
  components: {
    Adult,Child
  },
  watch :{

  },
  methods : {
    ...mapMutations(['addOneLicence','minusCount','addCertificat','back','componentKeyValue','paramRoute']),
    licencePass(licence) {
      console.log( licence.licence.licencie.certificat != undefined || licence.licence.licencie.certificat != "",'certificat licence dans licence Pass');
      licence.licence.licencie.ref_certif = null;
       var data = new FormData();
       if(licence.licence.licencie.certificat != undefined && licence.licence.licencie.certificat != ""){
        data.append("certificat", licence.licence.licencie.certificat);
       var uniqId = Date.now();
       data.append("ref",uniqId );
       inscription.downloadCertificat(data).then (res => {
         console.log(res.data.id,res.data, 'after download file');
         licence.licence.licencie.ref_certif = res.data;
         console.log( licence.licence.licencie);
       });

       }

       console.log(licence.type);
       if(!this.backValue && this.$store.state.indexRoute == this.$route.params.id){
         this.minusCount(licence.type.toUpperCase());
       }
     
      licence.licence.type = licence.type.toUpperCase();
     
      this.addOneLicence({'licence' : licence.licence,'index':this.$route.params.id});
       
      
        let valueCounter = Object.values(this.$store.state.counter);
      console.log(valueCounter,'valueCounter');
      var newForm=false;
      
      valueCounter.map(count =>{
        console.log(count);
       if(count >0){
         newForm = true;        
       }
       
      }) 
        var c =0;
      this.$store.state.licence.map(obj => { 
        c = c + obj.quantity;
      })



      console.log(newForm,'newForm');





       let index = this.$store.state.indexRoute;
       console.log(index,'index',c,'c');
      if(newForm == true && this.$store.state.licences[index+1]== undefined){
        console.log('new form');
         
       console.log(this.$store.state.licences);
         this.initlicence();
         console.log(this.licences,'licences after init')
         console.log('param route',this.$route.params.id );
       
        console.log(index)
        this.paramRoute(index+1);
               console.log(index,'index');
        this.$router.push({ name: "Inscription", params: { id: index+1 } });
        console.log(this.componentKey);
        this.back(false);
       this.forceRerender();
       
      }else if((newForm==false && index < c) || (newForm==true && this.$store.state.licences[index+1]!= undefined) ){
        console.log('retour form suivant')
        this.paramRoute(index+1);
         this.initlicence();
         this.back(false)
      
        this.$router.push({ name: "Inscription", params: { id: index+1 } });
           this.forceRerender();
           
      }
      
      else{
          this.$router.push({ name: "Acheteur" });
          this.back(false)
      }     

    },
    backPage(){
      this.$router.go(-1);
      this.save();
     this.back(true);
     this.forceRerender();
     this.paramRoute(this.$store.state.indexRoute -1)

    },
     save(){
      if(this.adulteForm && this.$store.state.counter['ADULTE_ENTRAINEMENT'] >0){
          this.licence.type = 'ADULTE_ENTRAINEMENT'
      }
      if(this.adulteForm && this.$store.state.counter['ADULTE_ENTRAINEMENT'] <=0 && this.$store.state.counter['ADULTE_LOISIR']){
        this.licence.type = 'ADULTE_LOISIR'
      }
      if(!this.adulteForm && this.childForm && this.$store.state.counter['ENFANT'] >0 ){
         this.licence.type = 'ENFANT'
      }
      
      console.log(this.licence.type,'type');
      this.addOneLicence({'licence' : this.licence,'index':this.$route.params.id});
    },
     forceRerender() {
       console.log('recharge composant');
        this.componentKey = this.$store.state.componentKey;
        console.log(this.$store.state);
        this.componentKey += 1;
        this.componentKeyValue(this.componentKey); 
        this.calc();
        this.$vuetify.goTo(0);
        console.log(this.componentKey);
      },
    initlicence(){
       return  this.licence = {
        licencie:{
          renouvellement:false,
          name:"",
          firstname:"",
          civilite:"",
          maiden:"",
          dateofbirth:null,
          lieu_naissance:"",
          dateofbirthFormatted: new Date().toISOString().substr(0, 10),
          dateofcertificat:null,
          nationality:"France",
          address:"",
          postal_code:"",
          city:"",
          phone:"",
          email:"",
          renouvellement_moins_3ans:true,
          questionnaire:{},
          num_licence : "",
          info_utilise_ffbad : false,
          accept_fede_tiers :false,
          result_accessible :false,
          photos_ffbad:false,
          photos_club_interne:true,
          photos_club_externe:true,
          sms:true,
          generali:false,
          child:{
            info_complémentaires:"",
            contact:[
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""}
            ]
          } 
    ,
          

        },
       
        club:{
          ligue:"Île de france",
          departement:"Essonne",
          club:"Les Fous du Volant (FVQ)"
        }
      }
    },
    SearchByLicence(licence){
      console.log(licence,'licence after emit');
               adherent.searchByAdherent(licence).then(res =>{
     console.log(res.data);
             if(res.status == 200 ){
       this.licence.licencie.name = res.data.name;
     this.licence.licencie.firstname = res.data.firstname;
     this.licence.licencie.maiden = res.data.maiden_name;
     this.licence.licencie.dateofbirth = res.data.birthday;
     this.licence.licencie.address = res.data.address;
     this.licence.licencie.phone = res.data.phone;
     this.licence.licencie.email = res.data.email;
     this.licence.licencie.num_licence = res.data.licence_number;
    this.licence.licencie.postal_code = res.data.postal_code;
     this.licence.licencie.city = res.data.city;
     this.licence.licencie.civilite = res.data.civilite;
     this.licence.licencie.sex = res.data.sex;
     this.licence.licencie.lieu_naissance = res.data.lieu_naissance;
     if (res.data.contacts != null) {
      this.licence.licencie.child.contact = res.data.contacts;
     }
  }
     if(res.status == 200 && res.data.date_certificat > '2022-07-01'){
        this.licence.licencie.renouvellement = true
     }
     
     if(res.status == 201){
       this.licence.licencie.renouvellement = false;
        this.licence.licencie.dateofbirth = "";
     this.licence.licencie.address = "";
     this.licence.licencie.phone = "";
     this.licence.licencie.email = "";
     this.licence.licencie.num_licence = "";
     this.licence.licencie.postal_code = "";
     this.licence.licencie.city = "";
     this.licence.licencie.sex = "";
      this.licence.licencie.lieu_naissance = "";
      this.licence.licencie.child.contact = [
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""}
            ];
     }

     });
    },
     SearchByName(user){
      
      adherent.searchByName(user).then(res =>{
        if(res.status == 200 ){
      this.licence.licencie.name = res.data.name;
     this.licence.licencie.firstname = res.data.firstname;
     this.licence.licencie.maiden = res.data.maiden_name;
     this.licence.licencie.dateofbirth = res.data.birthday;
     this.licence.licencie.address = res.data.address;
     this.licence.licencie.phone = res.data.phone;
     this.licence.licencie.email = res.data.email;
     this.licence.licencie.num_licence = res.data.licence_number;
      this.licence.licencie.postal_code = res.data.postal_code;
     this.licence.licencie.city = res.data.city;
     this.licence.licencie.civilite = res.data.civilite;
     this.licence.licencie.sex = res.data.sex;
     this.licence.licencie.lieu_naissance = res.data.lieu_naissance;
     if (res.data.contacts != null) {
      this.licence.licencie.child.contact = res.data.contacts;
     }     
    }
   
    if(res.status == 200 && res.data.date_certificat > '2021-07-01'){
        this.licence.licencie.renouvellement = true
     }
     
     if(res.status == 201){
       this.licence.licencie.renouvellement = false;
        this.licence.licencie.dateofbirth = "";
     this.licence.licencie.address = "";
     this.licence.licencie.phone = "";
     this.licence.licencie.email = "";
     this.licence.licencie.num_licence = "";
     this.licence.licencie.postal_code = "";
     this.licence.licencie.city = "";
     this.licence.licencie.sex = "";
      this.licence.licencie.lieu_naissance = "";
      this.licence.licencie.child.contact = [
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""},
              {name:"",parente:"",phone:""}
            ];
     }

     });
    },
    parseDate (date) {
      console.log('parse date', date);
        if (!date) return null

        const [year, month, day] = date.split('-')
        this.licence.licencie.dateofbirth =`${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`
        console.log( 'date of birth',this.licence.licencie.dateofbirth);
      },
      calc(){
          this.backValue = this.$store.state.back;
          console.log(this.backValue);
          console.log(this.$store.state.licences[this.$store.state.indexRoute+1]  );
          console.log(this.$store.state.indexRoute);
          console.log(this.$store.state.licences);
          console.log(this.$store.state.licences[this.$store.state.indexRoute-1]  !== undefined && this.backValue);
          console.log(this.backValue);
         var count = this.$store.state.counter;
        this.initlicence();
        var adulte = false;
        var child = false;
console.log(this.$route.params.id);
if((this.$store.state.licences[this.$store.state.indexRoute-1]  !== undefined && this.backValue )|| 
(this.$store.state.licences[this.$store.state.indexRoute]  !== undefined && !this.backValue )){
      if(this.backValue){
        var licence = this.$store.state.licences[this.$route.params.id-1];
      }else{
        var licence = this.$store.state.licences[this.$route.params.id];
      }
      console.log(licence.licencie.child,'licence.licencie.child');
      this.licence.licencie.name = licence.licencie.name;
     this.licence.licencie.firstname = licence.licencie.firstname;
     this.licence.licencie.maiden = licence.licencie.maiden_name;
     this.licence.licencie.dateofbirth = licence.licencie.dateofbirth;
     this.licence.licencie.address = licence.licencie.address;
     this.licence.licencie.phone = licence.licencie.phone;
     this.licence.licencie.email = licence.licencie.email;
     this.licence.licencie.num_licence = licence.licencie.num_licence;
    this.licence.licencie.postal_code = licence.licencie.postal_code;
     this.licence.licencie.city = licence.licencie.city;
     this.licence.licencie.civilite = licence.licencie.civilite;
     this.licence.licencie.sex = licence.licencie.sex;
     this.licence.licencie.lieu_naissance = licence.licencie.lieu_naissance;
     this.licence.type= licence.type;
     this.licence.licencie.info_utilise_ffbad = licence.licencie.info_utilise_ffbad;
     this.licence.licencie.accept_fede_tiers = licence.licencie.accept_fede_tiers;
     this.licence.licencie.result_accessible = licence.licencie.result_accessible;
     this.licence.licencie.photos_ffbad = licence.licencie.photos_ffbad;
     this.licence.licencie.photos_club_interne = licence.licencie.photos_club_interne;
     this.licence.licencie.photos_club_externe = licence.licencie.photos_club_externe;
     this.licence.licencie.generali = licence.licencie.generali;
     this.licence.licencie.sms = licence.licencie.sms;
     this.licence.licencie.displayFileInput = licence.licencie.displayFileInput;
     this.licence.licencie.dateofcertificat = licence.licencie.dateofcertificat;
     this.licence.licencie.questionnaire.Q1 = licence.licencie.questionnaire.Q1;
     this.licence.licencie.questionnaire.Q2 = licence.licencie.questionnaire.Q2;
     this.licence.licencie.questionnaire.Q3 = licence.licencie.questionnaire.Q3;
     this.licence.licencie.questionnaire.Q4 = licence.licencie.questionnaire.Q4;
     this.licence.licencie.questionnaire.Q5 = licence.licencie.questionnaire.Q5;
     this.licence.licencie.questionnaire.Q6 = licence.licencie.questionnaire.Q6;
     this.licence.licencie.questionnaire.Q7 = licence.licencie.questionnaire.Q7;
     this.licence.licencie.questionnaire.Q8 = licence.licencie.questionnaire.Q8;
     this.licence.licencie.questionnaire.Q9 = licence.licencie.questionnaire.Q9;
     this.licence.licencie.questionnaire.Q10 = licence.licencie.questionnaire.Q10;
     this.licence.licencie.questionnaire.Q11 = licence.licencie.questionnaire.Q11;
     this.licence.licencie.questionnaire.Q12 = licence.licencie.questionnaire.Q12;
     this.licence.licencie.questionnaire.Q13 = licence.licencie.questionnaire.Q13;
     this.licence.licencie.questionnaire.Q14 = licence.licencie.questionnaire.Q14;
     this.licence.licencie.questionnaire.Q15 = licence.licencie.questionnaire.Q15;
     this.licence.licencie.questionnaire.Q16 = licence.licencie.questionnaire.Q16;
     this.licence.licencie.questionnaire.Q17 = licence.licencie.questionnaire.Q19;
     this.licence.licencie.questionnaire.Q18 = licence.licencie.questionnaire.Q18;
     this.licence.licencie.questionnaire.Q19 = licence.licencie.questionnaire.Q19;
     this.licence.licencie.questionnaire.Q20 = licence.licencie.questionnaire.Q20;
     this.licence.licencie.questionnaire.Q21 = licence.licencie.questionnaire.Q21;
     this.licence.licencie.questionnaire.Q22 = licence.licencie.questionnaire.Q22;
     this.licence.licencie.questionnaire.Q23 = licence.licencie.questionnaire.Q23;
     this.licence.licencie.questionnaire.Q24 = licence.licencie.questionnaire.Q24;
     this.licence.licencie.questionnaire.Q25 = licence.licencie.questionnaire.Q25;

     this.licence.licencie.renouvellement = licence.licencie.renouvellement;
     this.licence.licencie.child.info_complémentaires = licence.licencie.child.info_complémentaires;
     console.log(licence.licencie.child.contact,'dfsdfslkdfsmlds');
     licence.licencie.child.contact.forEach((contact,i) => {
       console.log(contact,'contact',i,'i')
       this.licence.licencie.child.contact[i].name = contact.name;
       this.licence.licencie.child.contact[i].parente = contact.parente;
       this.licence.licencie.child.contact[i].phone = contact.phone;
     });

     if(this.licence.licencie.child.contact[0].name == ''){
       this.adulteForm = true;
       this.childForm = false;
     }else{
       this.adulteForm = false;
       this.childForm = true;
     }
 
     console.log(this.licence,'licence by state');






      }else{
 for (var property in count){
        console.log(count[property],property,'property');
        if(property.search('ADULTE') != -1 && count[property] >0 ) {
          adulte = true;
        }
         if(property.search('ENFANT') != -1 && count[property] >0 ) {
          child = true;
        }
      };
      this.adulteForm = adulte;
      this.childForm = child;
      }
      console.log(this.adulteForm,this.childForm,'adultForm','childForm');
      }
  },
  created () {
    console.log('created');

    this.componentKey = this.$store.state.componentKey;
        console.log(this.$store.state);
        this.componentKey += 1;
        this.componentKeyValue(this.componentKey); 


     console.log(this.$store.state.licences,'tester si state licences ',this.$store.state.licences[this.$route.params.id],this.$route.params.id);
    console.log(this.$store.state.componentKey, 'created component key');
     this.backValue = this.$store.state.back;
          console.log(this.backValue);
    var count = this.$store.state.counter;
    this.initlicence();
    let index = this.$route.params.id;
    console.log(Object.keys(count).length === 0 && count.constructor === Object, 'test objet vide');
    if(Object.keys(count).length === 0 && count.constructor === Object === true){
      this.$router.push({ name: "Home" });
    }else {
   var adulte = false;
   var child = false;



      console.log(this.$store.state.licences,'tester si state licences ',this.$store.state.licences[this.$route.params.id],this.$route.params.id);
      if(this.$store.state.licences[this.$route.params.id] !== undefined){
      var licence = this.$store.state.licences[this.$route.params.id];
      
      this.licence.licencie.name = licence.licencie.name;
     this.licence.licencie.firstname = licence.licencie.firstname;
     this.licence.licencie.maiden = licence.licencie.maiden_name;
     this.licence.licencie.dateofbirth = licence.licencie.dateofbirth;
     this.licence.licencie.address = licence.licencie.address;
     this.licence.licencie.phone = licence.licencie.phone;
     this.licence.licencie.email = licence.licencie.email;
     this.licence.licencie.num_licence = licence.licencie.num_licence;
    this.licence.licencie.postal_code = licence.licencie.postal_code;
     this.licence.licencie.city = licence.licencie.city;
     this.licence.licencie.civilite = licence.licencie.civilite;
     this.licence.licencie.sex = licence.licencie.sex;
     this.licence.licencie.lieu_naissance = licence.licencie.lieu_naissance;
     this.licence.type= licence.type;
     this.licence.licencie.info_utilise_ffbad = licence.licencie.info_utilise_ffbad;
     this.licence.licencie.accept_fede_tiers = licence.licencie.accept_fede_tiers;
     this.licence.licencie.result_accessible = licence.licencie.result_accessible;
     this.licence.licencie.photos_ffbad = licence.licencie.photos_ffbad;
     this.licence.licencie.photos_club_interne = licence.licencie.photos_club_interne;
     this.licence.licencie.photos_club_externe = licence.licencie.photos_club_externe;
     this.licence.licencie.generali = licence.licencie.generali;
     this.licence.licencie.sms = licence.licencie.sms;
     this.licence.licencie.dateofcertificat = licence.licencie.dateofcertificat;
     this.licence.licencie.questionnaire.Q1 = licence.licencie.questionnaire.Q1;
     this.licence.licencie.questionnaire.Q2 = licence.licencie.questionnaire.Q2;
     this.licence.licencie.questionnaire.Q3 = licence.licencie.questionnaire.Q3;
     this.licence.licencie.questionnaire.Q4 = licence.licencie.questionnaire.Q4;
     this.licence.licencie.questionnaire.Q5 = licence.licencie.questionnaire.Q5;
     this.licence.licencie.questionnaire.Q6 = licence.licencie.questionnaire.Q6;
     this.licence.licencie.questionnaire.Q7 = licence.licencie.questionnaire.Q7;
     this.licence.licencie.questionnaire.Q8 = licence.licencie.questionnaire.Q8;
     this.licence.licencie.questionnaire.Q9 = licence.licencie.questionnaire.Q9;
     this.licence.licencie.questionnaire.Q10 = licence.licencie.questionnaire.Q10;
     this.licence.licencie.questionnaire.Q11 = licence.licencie.questionnaire.Q11;
     this.licence.licencie.questionnaire.Q12 = licence.licencie.questionnaire.Q12;
     this.licence.licencie.questionnaire.Q13 = licence.licencie.questionnaire.Q13
     this.licence.licencie.questionnaire.Q14 = licence.licencie.questionnaire.Q14;
     this.licence.licencie.questionnaire.Q15 = licence.licencie.questionnaire.Q15;
     this.licence.licencie.questionnaire.Q16 = licence.licencie.questionnaire.Q16;
     this.licence.licencie.questionnaire.Q17 = licence.licencie.questionnaire.Q17;
     this.licence.licencie.questionnaire.Q18 = licence.licencie.questionnaire.Q18;
     this.licence.licencie.questionnaire.Q19 = licence.licencie.questionnaire.Q19;
     this.licence.licencie.questionnaire.Q20 = licence.licencie.questionnaire.Q20;
     this.licence.licencie.questionnaire.Q21 = licence.licencie.questionnaire.Q21;
     this.licence.licencie.questionnaire.Q22 = licence.licencie.questionnaire.Q22;
     this.licence.licencie.questionnaire.Q23 = licence.licencie.questionnaire.Q23;
     this.licence.licencie.questionnaire.Q24 = licence.licencie.questionnaire.Q24;
     this.licence.licencie.questionnaire.Q25 = licence.licencie.questionnaire.Q25;
    
     this.licence.licencie.renouvellement = licence.licencie.renouvellement;
     this.licence.licencie.displayFileInput = licence.licencie.displayFileInput;
        this.licence.licencie.child.info_complémentaires = licence.licencie.child.info_complémentaires;

     licence.licencie.child.contact.forEach((contact,i) => {
    
       this.licence.licencie.child.contact[i].name = contact.name;
       this.licence.licencie.child.contact[i].parente = contact.parente;
       this.licence.licencie.child.contact[i].phone = contact.phone;
     });
 
     console.log(this.licence,'licence by state created');






      }else{
 for (var property in count){
        console.log(count[property],property,'property');
        if(property.search('ADULTE') != -1 && count[property] >0 ) {
          adulte = true;
        }
         if(property.search('ENFANT') != -1 && count[property] >0 ) {
          child = true;
        }
      };
      this.adulteForm = adulte;
      this.childForm = child;
      }














     
  console.log(this.licence,'licence dans le created end');
    }

     
  }
  
}
</script>