import Axios from 'axios';


const RESOURCE_NAME = '/adherents';
//const token = localStorage.getItem('token');

export default {
    
    searchByAdherent(licence) {
        return Axios.get(`${RESOURCE_NAME}/${licence}`);
    },
    searchByName(user) {
        return Axios.post(`${RESOURCE_NAME}/findByName`,user);
    },
    list(token){
            console.log(token,'token');
        return Axios.get(`${RESOURCE_NAME}?token=`+token);
    },
    complete(id,token){
        return Axios.get(`dossier/${id}/completed?token=`+token);
    },
    paye(id,token){
        return Axios.get(`dossier/${id}/paye?token=`+token);
    },
    licencie(id,token){
        return Axios.get(`${RESOURCE_NAME}/${id}/licencie?token=`+token);
    },
    update(id,data,token){
        return Axios.put(`${RESOURCE_NAME}/${id}?token=`+token,data);
    },
    downloadFile(token){
        return Axios.get(`poona?token=`+token);
    }


    
    

};